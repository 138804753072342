





























import Vue from 'vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import PasswordInput from '@/components/shared/forms/PasswordInput.vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  components: {
    SubmitButton,
    PasswordInput
  },
  data() {
    return {
      user: {},
      resettingPassword: false
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    errors(): any {
      return this.usersStore.user.validationErrors;
    }
  },
  methods: {
    async submit(user) {
      const query = this.$route.query;
      user.id = query.id;
      user.token = query.token;
      this.resettingPassword = true;
      const resetDone = await this.usersStore.resetPassword(user);
      this.resettingPassword = false;
      if (resetDone) {
        this.$router.push({ name: 'Login' });
      }
    }
  }
});
